/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import * as React from "react";
import { navbarConstants } from "@/utils/constants";
import { useClickOutside, useTrackPosition } from "@/hooks";
import { useEffect, useRef, useState } from "react";
import authApi from "@/utils/api/auth/auth.api";
import userApi, { IGetProfileResponse } from "@/utils/api/user";
import { NAV_BAR_TYPE } from "@/utils/constants/navbar.constant";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { notificationApi } from "@/utils/api";
import { FetchNotificationsResponseParams } from "@/utils/api/notification";
import { useIsMounted } from "usehooks-ts";

const simple = (props: any) => (
  <React.Fragment>{props.children}</React.Fragment>
);
const NoSsr = dynamic(() => Promise.resolve(simple), {
  ssr: false,
});

export interface IAppProps {}

export default function Header(props: IAppProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const { i18n } = useTranslation();
  const { pathname, asPath, query } = router;
  const { locale } = router;
  const languageDropdownRef = useRef<HTMLDivElement>(null);

  const menuRef = useRef<HTMLInputElement>(null);
  const [isShowMemberMenu, setShowMemberMenu] = useState<boolean>(false);
  const [isMobile, setIfMobile] = useState<boolean>(false);
  const [profile, setProfile] = useState<IGetProfileResponse | null>(null);
  const [isShowMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [isShowLanguageDropdown, setShowLanguageDropdown] =
    useState<boolean>(false);
  const [notification, setNotification] = useState<
    FetchNotificationsResponseParams[]
  >([]);

  let onLogout = async () => {
    try {
      let res = await authApi.logout({
        params: {
          device_token: "vtl",
          token: localStorage.getItem("token")!,
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("member_id");
      router.reload();
    } catch (error) {
      console.log(error);
    }
  };

  let getProfile = async () => {
    try {
      let res = await userApi.getProfile({
        params: {
          token: localStorage.getItem("token")!,
          language: locale === "en-US" ? "eng" : "zho",
        },
      });
      if (res.data.status) {
        setProfile(res.data.params);
      } else {
        alert("Sorry, Something goes wrong! Please try again!");
        router.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getNotification = async () => {
    try {
      let res = await notificationApi.fetchEvents({
        params: {
          token: localStorage.getItem("token")!,
          language: locale === "en-US" ? "eng" : "zho",
        },
      });
      setNotification(res.data.params);
    } catch (e) {
      console.log(e);
    }
  };

  let FetchData = async () => {
    await getProfile();
    await getNotification();
  };

  let gotoPage = (path: string) => {
    router.push(path);
  };

  let hiddenMenu = () => {
    setShowMemberMenu(false);
  };

  let onClickMemberIcon = () => {
    if (localStorage.getItem("token")) {
      setShowMemberMenu(!isShowMemberMenu);
    } else gotoPage("/login");
  };

  let onChooseMenuItem = (item: NAV_BAR_TYPE) => {
    if (isMobile) setShowMenuMobile(false);
    if (item.auth && !localStorage.getItem("token")) {
      gotoPage("/login");
    } else gotoPage(item.path);
  };

  let onTranslate = (lang: string) => {
    if (!isMobile) setShowLanguageDropdown(false);
    if (lang == i18n.language) return;
    router.push({ pathname, query }, asPath, { locale: lang });
    if (typeof window !== undefined)
      (window as any).NextPublic.lang = locale as any;
  };

  let gotoProfileMobile = () => {
    gotoPage(`/${profile ? "account" : "login"}`);
    setShowMenuMobile(false);
  };

  let onHideLangDropdown = () => {
    setShowLanguageDropdown(false);
  };

  const position = useTrackPosition();
  useClickOutside([menuRef], hiddenMenu);

  let listenResizeWindows = () => {
    setIfMobile(window.innerWidth < 1280);
  };

  let gotoNotification = () => {
    if (localStorage.getItem("token")) gotoPage("/notification");
    else gotoPage("/login");
  };

  useClickOutside([languageDropdownRef], onHideLangDropdown);

  useEffect(() => {
    if (isMounted()) return;
    window.addEventListener("resize", listenResizeWindows);
    listenResizeWindows();
    if (localStorage.getItem("token")) {
      FetchData();
    }
  }, []);

  useEffect(() => {
    if (!isMounted()) return;
    window.addEventListener("resize", listenResizeWindows);
    listenResizeWindows();
    if (localStorage.getItem("token")) {
      FetchData();
    }
  }, [locale]);

  let isMounted = useIsMounted();

  return (
    <>
      {isMobile ? (
        <div className="w-screen h-[94px] px-5 flex flex-row justify-between items-center bg-white fixed top-0 left-0 z-40 overflow-auto">
          <img
            className="w-6 h-6"
            src="/icon/menu-mobile.svg"
            alt=""
            onClick={() => setShowMenuMobile(true)}
          />
          <img
            className="w-auto h-[62px]"
            src="/img/logo-mobile.png"
            alt=""
            onClick={() => gotoPage("/")}
          />
          <img
            className="w-6 h-6"
            src={`/icon/Bell-${notification.length === 0 ? "0" : "1"}.svg`}
            alt=""
            onClick={() => gotoPage("/notification")}
          />
          {isShowMenuMobile && (
            <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-[#E5E0DC] flex flex-col items-start justify-start">
              <div className="w-full h-fit p-4 flex flex-col justify-center items-center gap-3 border-b-[1px] border-[#C2B7AD] border-solid">
                <img
                  className="pc:mr-[15px] w-[70px] h-[70px] rounded-full"
                  src="/img/avatar.png"
                  alt=""
                  onClick={gotoProfileMobile}
                />
                <p
                  className="text-[#454749] text-xs font-semibold uppercase"
                  onClick={gotoProfileMobile}
                >
                  {profile ? profile.name : "Sign in now"}
                </p>
                <img
                  className="top-4 right-4 absolute"
                  src="/icon/close-menu.png"
                  alt=""
                  onClick={() => setShowMenuMobile(false)}
                />
              </div>
              <div className="w-screen h-fit flex flex-col">
                {navbarConstants.NAV_BAR.map((item, index) => {
                  return (
                    <p
                      className="p-5 text-[#816F5F] text-base font-medium uppercase"
                      key={index}
                      onClick={() => onChooseMenuItem(item)}
                    >
                      {t(item.text)}
                    </p>
                  );
                })}
                <div
                  className="w-full h-fit flex flex-row justify-between items-center p-5"
                  onClick={() =>
                    setShowLanguageDropdown(!isShowLanguageDropdown)
                  }
                >
                  <p className="text-[#816F5F] text-base font-medium uppercase">
                    {t("language")}
                  </p>
                  <img
                    className="w-5 h-5"
                    src="/icon/language-dropdown-mobile.svg"
                    alt=""
                  />
                </div>
                {isShowLanguageDropdown && (
                  <NoSsr>
                    <div className="w-full h-fit flex flex-col justify-between items-center gap-[1px]">
                      <div
                        className="w-full h-fit flex flex-row justify-between items-center py-5 px-6 bg-[#816F5F]"
                        onClick={() => onTranslate("en-US")}
                      >
                        <p className="text-white text-base font-medium uppercase">
                          EN
                        </p>
                        {i18n.language === "en-US" && (
                          <img
                            className="w-5 h-5"
                            src="/icon/check-language.svg"
                          ></img>
                        )}
                      </div>
                      <div
                        className="w-full h-fit flex flex-row justify-between items-center py-5 px-6 bg-[#816F5F]"
                        onClick={() => onTranslate("zh-HK")}
                      >
                        <p className="text-white text-base font-medium uppercase">
                          中
                        </p>
                        {i18n.language === "zh-HK" && (
                          <img
                            className="w-5 h-5"
                            src="/icon/check-language.svg"
                          ></img>
                        )}
                      </div>
                    </div>
                  </NoSsr>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`w-screen h-fit flex justify-center items-center fixed z-40 ${
            position < 20
              ? "py-9 bg-transparent"
              : "py-5 bg-white drop-shadow-lg"
          }`}
        >
          <div className="flex flow-row justify-end w-[1280px] items-center">
            <div className="w-[569px] h-[25px] flex flex-row justify-between items-center">
              {navbarConstants.NAV_BAR.map((item, ind) => {
                return (
                  <span
                    className="roboto font-semibold text-xl leading-[100%] text-center tracking-[0.01em] text-[#212325] cursor-pointer "
                    onClick={() => onChooseMenuItem(item)}
                    key={ind}
                  >
                    {t(item.text)}
                  </span>
                );
              })}
            </div>
            <div className="relative w-fit gap-5 flex flex-row justify-between items-center ml-32">
              <img
                className=" cursor-pointer"
                src={`/icon/Bell-${notification.length === 0 ? "0" : "1"}.svg`}
                alt=""
                onClick={() => gotoNotification()}
              />
              <div>
                <div
                  className="flex flex-row justify-center items-center border px-2.5 py-[5px] rounded-[5px] border-solid border-[#52473D] relative cursor-pointer"
                  onClick={() => setShowLanguageDropdown(true)}
                >
                  <img src="/icon/global.svg" alt="" />
                  <span className="font-medium text-base leading-[100%] text-center tracking-[0.01em] mx-[10px]">
                    {locale === "en-US" ? "English" : "Hong Kong"}
                  </span>
                  <img src="/icon/Arrow - Down 2.svg" alt="" />
                </div>
                {isShowLanguageDropdown && (
                  <div
                    className="w-full h-fit absolute top-0 left-0 flex flex-col justify-center items-stretch border rounded-[5px] border-solid border-[#52473D] bg-white z-50 gap-3"
                    ref={languageDropdownRef}
                  >
                    <div
                      className="flex flex-row justify-start items-center w-full h-fit py-2 hover:bg-[#E5E0DC] px-2.5 cursor-pointer gap-2.5"
                      onClick={() => onTranslate("zh-HK")}
                    >
                      <img src="/icon/global.svg" alt="" />
                      <span className="font-medium text-base leading-[100%] text-center tracking-[0.01em]">
                        Hong Kong
                      </span>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center w-full h-fit py-2 hover:bg-[#E5E0DC] px-2.5 cursor-pointer gap-2.5"
                      onClick={() => onTranslate("en-US")}
                    >
                      <img src="/icon/global.svg" alt="" />
                      <span className="font-medium text-base leading-[100%] text-center tracking-[0.01em]">
                        English
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <img
                  className="rounded-full relative cursor-pointer"
                  width={50}
                  height={50}
                  src="/img/avatar.png"
                  alt=""
                  onClick={() => onClickMemberIcon()}
                />
                {isShowMemberMenu && (
                  <div
                    className="flex flex-col absolute w-[186px] right-0 top-[56px] drop-shadow-xl rounded border-[1px]"
                    ref={menuRef}
                  >
                    <div
                      className="flex flex-row h-[50px] w-fulls bg-white items-center px-[17px] py-[15px] border-b-[1px]  border-[##ECEDEF] rounded-t cursor-pointer"
                      onClick={() => gotoPage("/account")}
                    >
                      <img
                        className="mr-[15px]"
                        height={24}
                        width={24}
                        src="/icon/profile.svg"
                        alt=""
                      />
                      <span className="text-base leading-[100%] text-[#454749]">
                        {t("Profile")}
                      </span>
                    </div>
                    <div
                      className="flex flex-row h-[50px] w-fulls bg-white items-center px-[17px] py-[15px] border-b-[1px] border-[##ECEDEF] rounded-b cursor-pointer"
                      onClick={() => onLogout()}
                    >
                      <img
                        className="mr-[15px]"
                        height={24}
                        width={24}
                        src="/icon/logout.svg"
                        alt=""
                      />
                      <span className="text-base leading-[100%] text-[#454749]">
                        {t("Logout")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
