/* eslint-disable @next/next/no-img-element */
import companySettingApi, {
  IGetAllSettingResponse,
} from "@/utils/api/companySetting";
import { gotoPage } from "@/utils/helpers/common";
import { useRouter } from "next/router";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";
import { useIsMounted } from "usehooks-ts";

const simple = (props: any) => (
  <React.Fragment>{props.children}</React.Fragment>
);
const NoSsr = dynamic(() => Promise.resolve(simple), {
  ssr: false,
});

export interface IAppProps {}

export default function Footer(props: IAppProps) {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { pathname, asPath, query } = router;
  const { locale } = router;

  const [isMobile, setIfMobile] = useState<boolean>(false);
  const [companySetting, setCompanySetting] =
    useState<IGetAllSettingResponse | null>(null);

  let getCompanySetting = async () => {
    try {
      let res = await companySettingApi.getAllSetting({
        params: {
          language: locale === "en-US" ? "eng" : "zho",
        },
      });
      setCompanySetting(res.data.params);
    } catch (error) {
      console.log(error);
    }
  };

  let listenResizeWindows = () => {
    setIfMobile(window.innerWidth < 1280);
  };

  let onTranslate = (lang: string) => {
    let _locale = lang === "English" ? "en-US" : "zh-HK";
    router.push({ pathname, query }, asPath, { locale: _locale });
    if (typeof window !== undefined)
      (window as any).NextPublic.lang = locale as any;
  };

  useEffect(() => {
    window.addEventListener("resize", listenResizeWindows);
    listenResizeWindows();

    if (isMounted()) return;
    getCompanySetting();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", listenResizeWindows);
    listenResizeWindows();

    if (!isMounted()) return;
    getCompanySetting();
  }, [locale]);

  let isMounted = useIsMounted();

  return (
    <div className="flex flex-col p-4 pc:px-[80px] pc:py-[30px] h-fit pc:h-[182px] w-screen relative">
      <img
        className="w-full h-full absolute top-0 left-0 -z-10"
        src={companySetting?.content_footer_image}
        alt=""
      />
      <div className="flex flex-col pc:flex-row pc:border-b-[1px] pc:border-gray-400 w-full h-1/2">
        <div className="w-full pc:w-1/2 h-full flex flex-col pc:flex-row justify-between items-start pc:items-center gap-3 pb-4 pc:p-0 border-b-[1px] border-solid border-[#B9DEDD] pc:border-0">
          <span className="font-semibold text-base pc:text-[32px] leading-[100%] text-center tracking-[0.01em] text-[#C33D44]">
            {companySetting?.content_company_name.toUpperCase()}
          </span>
          <div className="flex flex-row justify-center items-center">
            <img src="/icon/call.svg" alt="" />
            <span className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white ml-3">
              {companySetting?.content_phone}
            </span>
          </div>
          <div className="flex flex-row justify-center items-center">
            <img src="/icon/mail.svg" alt="" />
            <span className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white ml-3">
              {companySetting?.content_email}
            </span>
          </div>
        </div>
        <div className="w-full pc:h-full pc:w-1/2 h-fit flex flex-col pc:flex-row justify-end items-start pc:items-center gap-3 py-4 pc:py-0 border-b-[1px] border-solid border-[#B9DEDD] pc:border-0">
          <span
            className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white pc:ml-3 cursor-pointer"
            onClick={() => gotoPage("/")}
          >
            {t("Home")}
          </span>
          <span
            className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white pc:ml-3 cursor-pointer"
            onClick={() => {
              localStorage.getItem("token")
                ? gotoPage("/e-coupon")
                : gotoPage("/login");
            }}
          >
            {t("eCoupon")}
          </span>
          <span
            className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white pc:ml-3 cursor-pointer"
            onClick={() => gotoPage("/promotion")}
          >
            {t("Promotion")}
          </span>
          <span
            className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white pc:ml-3 cursor-pointer"
            onClick={() => gotoPage("/term-and-conditions")}
          >
            {t("Terms & Conditions")}
          </span>
          <span
            className="font-normal text-sm leading-[100%] text-center tracking-[0.01em] text-white pc:ml-3 cursor-pointer"
            onClick={() => gotoPage("privacy-and-policy")}
          >
            {t("Privacy & Policy")}
          </span>
        </div>
      </div>
      <div className="flex flex-row w-full h-1/2 justify-between items-center py-4 pc:py-0">
        <span className="font-normal text-xs leading-[100%] tracking-[0.01em] text-white">
          {companySetting?.content_copyright}
        </span>
        {!isMobile && (
          <NoSsr>
            <div className="flex flex-row justify-between items-center">
              <span
                className={`${
                  i18n.language === "en-US" ? "font-medium" : "font-thin"
                } text-sm leading-[100%] tracking-[0.01em] text-white ml-[10px] cursor-pointer`}
                onClick={() => onTranslate("English")}
              >
                EN
              </span>
              <span
                className={`${
                  i18n.language !== "en-US" ? "font-medium" : "font-thin"
                } text-sm leading-[100%] tracking-[0.01em] text-white ml-[10px] cursor-pointer`}
                onClick={() => onTranslate("Hongkong")}
              >
                中
              </span>
            </div>
          </NoSsr>
        )}
      </div>
    </div>
  );
}
